<template>
  <div class="results-pane">
    <div class="status">
      <hub-icon name="text-box-search-outline" size="md" />
      <label>Nothing found. Please clarify your query</label>
    </div>
    <div class="help">
      <div class="column">
        <div class="row header">If you're looking for...</div>
        <div class="row query">words <span class="request">draft</span> or <span class="request">response</span></div>
        <div class="row query">words <span class="request">draft</span> and <span class="request">response</span></div>
        <div class="row query">phrase <span class="request">non-final</span></div>
        <div class="row query">tasks where title contains the phrase<span class="request">draft response</span></div>
        <div class="row query">
          <pre>milestones with word <span class="request">response</span> within three words of <span class="request">action</span> in title</pre>
        </div>
        <div class="row query">
          <pre>milestones with word <span class="request">response</span> and not word <span class="request">action</span> in title</pre>
        </div>
        <div class="row query">you can group queries</div>
        <div class="row query">task title containing all combinations of word <span class="request">report</span></div>
      </div>
      <div class="column">
        <div class="row header">Try next filters...</div>
        <div class="row"><span class="example">draft OR response</span> or <span class="example">draft response</span></div>
        <div class="row"><span class="example">draft AND response</span> or <span class="example">"draft response"</span></div>
        <div class="row"><span class="example">"non-final"</span></div>
        <div class="row">
          <pre><span class="example">title: "draft response"</span> (options: <span class="example">notes</span>, <span class="example">workflow.milestoneTitle</span>)</pre>
        </div>
        <div class="row"><span class="example">workflow.milestoneTitle: "response action"~3</span></div>
        <div class="row"><span class="example">workflow.milestoneTitle: response NOT amendment</span></div>
        <div class="row"><span class="example">(response AND amendment) NOT final</span></div>
        <div class="row">
          <span class="example">title: report*</span> (<span class="example">*</span> - zero or many characters, <span class="example">?</span> -
          exactly one character)
        </div>
        <div class="row flex-end">
          <a href="https://lucene.apache.org/core/2_9_4/queryparsersyntax.html" target="_blank">more extreme syntax</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
export default {
  components: {
    'hub-icon': Icon
  }
};
</script>

<style lang="scss" scoped>
.results-pane {
  height: 100%;
  padding-top: 15px;

  .status {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    padding-bottom: 20px;
    padding-left: 15px;
  }

  .help {
    background: var(--theme-surface);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto 1fr;
    justify-items: flex-start;
    align-self: flex-start;
    padding: 15px;

    .column {
      padding-right: 20px;
    }

    .row {
      height: 35px;
      letter-spacing: 0.03rem;
      color: var(--theme-on-surface);
      display: flex;
      align-items: center;

      &.flex-end {
        padding-top: 25px;
        justify-content: flex-end;
      }

      a {
        color: var(--theme-primary);
      }

      &.query::before {
        content: '•';
        display: inline-block;
        margin-right: 10px;
      }
    }

    .header {
      font-size: 1rem;
      font-style: italic;
    }

    .request {
      text-decoration: underline;
      font-family: monospace;
      color: var(--theme-primary);
      margin: 0 2px;
    }

    .example {
      font-size: 0.9rem;
      font-family: monospace;
      color: var(--theme-primary);
      margin: 0 2px;
    }
  }
}
</style>
