<template>
  <MilestonesOverview />
</template>

<script>
import MilestonesOverview from '@/components/milestones-overview/Overview.vue';

export default {
  components: {
    MilestonesOverview
  },
  mounted() {}
};
</script>
