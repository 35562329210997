<template>
  <section ref="listRootRef" class="overview-wrapper">
    <header class="overview-header" :class="{ 'with-panel': settingsPanelShown }">
      <FiltersPanel
        :filter="filter"
        :assignee="assignee"
        :tags-option="tagsOption"
        :sort="sort"
        @onFilterChanged="onFilterChanged"
        @onTagsChange="onTagsChange"
        @onSortChange="onSortChange"
        @onAssigneeChange="onAssigneeChange"
      />
      <div v-if="isReady">
        <label v-if="collection.length > 0">{{ collection?.length }} of {{ total }}</label>
      </div>
    </header>
    <ColumnsHeaders :settings-panel-shown="settingsPanelShown" class="column-headers" />
    <Searching v-if="!isReady" />
    <Empty v-else-if="failed || !collection?.length" />
    <div v-else-if="collection.length" class="overview">
      <overview-list :assignee="assignee" :assignee-teams="assigneeTeams" :collection="collection" @more="more" @refresh="refresh" />
      <li v-if="!running && total > collection.length">
        <hub-observe :get-root-ref="() => $refs['listRootRef']" @intersect="more">
          <hub-button variant="text" @click="more">more<hub-icon name="refresh" spin></hub-icon></hub-button>
        </hub-observe>
      </li>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import OverviewList from './components/OverviewList';
import Empty from './components/Empty';
import ColumnsHeaders from './components/ColumnsHeaders';
import Searching from './components/Searching';
import FiltersPanel from './components/FiltersPanel';
import Button from '@/components/common/Button';
import Observer from '../inventions/Observer.vue';
import Icon from '@/components/common/Icon';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    OverviewList,
    Empty,
    ColumnsHeaders,
    Searching,
    FiltersPanel,
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-observe': Observer
  },
  data() {
    return {
      isReady: true,
      sort: 'cdd',
      timeoutRef: null,
      isRefreshing: false,
      assignee: '',
      assigneeTeams: [],
      filter: '',
      tagsOption: []
    };
  },
  computed: {
    ...mapState({
      running: s => s.milestonesOverview.isRequestPending,
      failed: s => s.milestonesOverview.isRequestFailed,
      collection: s => s.milestonesOverview.data,
      total: s => s.milestonesOverview.total,
      me: s => s.identity.email,
      settingsPanelShown: s => s.settings.panelShown,
      defaultSettings: s => s.milestonesOverview.defaultSettings
    })
  },
  async created() {
    this.executeRefreshTimeout();
    await this.$store.dispatch('settings/initialize', { defaultValue: this.defaultSettings });
  },
  async unmounted() {
    this.$store.dispatch('settings/reset');
    clearTimeout(this.timeoutRef);
    await this.$store.dispatch('tasks/disconnect');
  },
  async mounted() {
    this.assignee = this.me;

    await this.$store.dispatch('tasks/connect');
    await this.load();
    await this.loadTeams();
  },
  methods: {
    async onSortChange(sort) {
      this.sort = sort;
      await this.load();
    },

    async onTagsChange(tags) {
      this.tagsOption = tags;
      await this.load();
    },

    async loadTeams() {
      try {
        let teams;
        if (!/^\w+?@\w+?\.\w+$/.test(this.assignee)) {
          teams = await httpClient.get(`/api/auth/teams/${this.assignee}/members`);
        } else {
          teams = await httpClient.get(`/api/auth/teams/member/${this.assignee}`).then(teams => teams.data.map(t => t.name));
        }
        this.assigneeTeams = teams.map(t => t.toLowerCase());
      } catch (e) {
        this.$toast.error({
          title: 'Failed to load user teams',
          message: `Color coding can be applied incorrectly`
        });
      }
    },
    async onAssigneeChange(value) {
      this.assignee = value ? value : this.me;
      await this.load();
      await this.loadTeams();
    },

    async onFilterChanged(value) {
      const cleanedChange = value ? value.replaceAll(/[\/,]/gi, '').trim() : '';
      if (this.filter === cleanedChange) {
        return;
      }

      this.filter = cleanedChange;

      await this.load();
    },

    async load() {
      if (!this.isReady) {
        return;
      }

      try {
        this.isReady = false;
        clearTimeout(this.timeoutRef);
        await this.$store.dispatch('milestonesOverview/getCollection', {
          sort: this.sort,
          assignee: this.assignee,
          filter: this.filter,
          tags: this.tagsOption
        });
        this.executeRefreshTimeout();
      } finally {
        this.isReady = true;
      }
    },

    executeRefreshTimeout() {
      this.timeoutRef = setTimeout(async () => {
        if (!this.isRefreshing && this.isReady) {
          await this.refresh();
          this.executeRefreshTimeout();
        }
      }, 60 * 1000);
    },

    async more() {
      if (this.running) {
        return;
      }

      await this.$store.dispatch('milestonesOverview/getCollection', {
        assignee: this.assignee,
        skip: this.collection.length,
        sort: this.sort,
        filter: this.filter
      });
    },
    async refresh() {
      if (!this.isReady) {
        return;
      }
      this.isRefreshing = true;
      try {
        await this.$store.dispatch('milestonesOverview/getCollection', {
          assignee: this.assignee,
          sort: this.sort,
          filter: this.filter,
          refresh: true,
          tags: this.tagsOption,
          size: Math.ceil(this.collection.length / 50) * 50
        });
      } finally {
        this.isRefreshing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--theme-on-background-accent);
}

.overview-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 0.5rem;
  position: relative;

  .center {
    display: flex;
    align-items: flex-end;
    padding: 10px;

    label {
      font-size: 0.8rem;
      color: var(--theme-on-surface-accent);
      padding-left: 7px;
      letter-spacing: 0.02rem;
    }
  }

  .left {
    justify-self: flex-start;
  }

  .right {
    justify-self: flex-end;
  }

  .overview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 5px;
    padding-right: 10px;
    background-color: var(--theme-surface);
    z-index: 5;
    width: 100%;
    position: sticky;
    top: 0;
  }

  .column-headers {
    width: 100%;
    position: sticky;
    top: 43px;
  }

  .overview {
    height: 100%;
  }
}
</style>
