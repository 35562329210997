<template>
  <div class="column-headers" :class="{ 'with-panel': settingsPanelShown, show2ARColumn }">
    <div></div>
    <label>Invention</label>
    <label>Milestone</label>
    <div class="task-mode">
      <label>{{ mode }}</label>
    </div>
    <label>Notes</label>
    <label v-if="show2ARColumn" class="right">Next 2AR</label>
    <label class="left">Client Date</label>
    <label class="right">Due Date</label>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    settingsPanelShown: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      mode: s => s.settings.current.taskViewMode?.value,
      settings: s => s.settings.current
    }),
    show2ARColumn() {
      return this.settings.columns?.value.find(c => c.title === 'Next 2AR')?.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.column-headers {
  display: grid;
  grid-template-columns: 30px minmax(200px, 0.2fr) 0.7fr 0.7fr 1fr 80px 80px;
  justify-items: flex-start;
  align-items: center;
  padding: 8px 0;
  padding-right: 2px;
  z-index: 4;
  border-bottom: 1px solid var(--theme-surface);
  background-color: var(--theme-background);

  &.show2ARColumn {
    grid-template-columns: 30px minmax(200px, 0.2fr) 0.7fr 0.7fr 1fr 80px 80px 80px;
  }

  .task-mode {
    display: flex;
    align-items: center;

    .splitter {
      padding: 0 5px;
    }

    .switch {
      padding: 3px 7px;
      opacity: 0.5;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        opacity: 1;
        background: var(--theme-primary);
        color: var(--theme-surface);
        border-radius: 2px;
      }
    }
  }

  label {
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--theme-on-background-accent);
  }
}
</style>
