<template>
  <ul ref="listRootRef" class="list overview-list">
    <transition-group name="flip-list">
      <li v-for="item of collection" :id="item.id" :key="item.id">
        <OverviewHeader
          :item="item"
          :is-expanded="expanded === `${item.id}_${item.nextMilestone.id}`"
          :for-user="assignee"
          :for-teams="assigneeTeams"
          @click="toggleOverview(item)"
          @refresh="$emit('refresh')"
        />
        <div
          v-if="expanded === `${item.id}_${item.nextMilestone.id}`"
          class="detailes-wrapper"
          :style="{ height: currentItemHeight && `${currentItemHeight}px` }"
        >
          <div class="details">
            <div v-if="!details[item.id] || details[item.id].isRequestPending" class="detailes-loading">
              <hub-icon name="loading" spin size="lg"></hub-icon>
            </div>
            <div v-else-if="details[item.id].isRequestFailed" class="error">Failed to load milestone list</div>
            <Transition name="fade">
              <div v-if="details[item.id] && !details[item.id].isRequestPending && !details[item.id].isRequestFailed">
                <Detailes
                  :for-user="assignee"
                  :for-teams="assigneeTeams"
                  :invention="item"
                  :expanded-milestone="item.nextMilestone.id"
                  :milestones="details[item.id].milestones"
                  @editTask="onEditTask"
                  @createTask="$e => onCreateTask(item, $e)"
                  @editMilestone="$e => editMilestone(item, $e)"
                />
              </div>
            </Transition>
          </div>
        </div>
      </li>
      <li v-if="running" key="spinner" class="details-loading">
        <hub-icon name="loading" spin size="lg"></hub-icon>
      </li>
    </transition-group>
  </ul>
  <TaskModal
    :selected="selected"
    :task-create-options="taskCreateOptions"
    @close="taskModalClosed"
    @createModalClose="onCreateTaskModalClosed"
    @edited="taskEdited"
  />
  <CreateMilestoneModal
    v-if="isMilestoneCreateModalVisible"
    :invention="milestoneCreateOptions.invention"
    :milestone="milestoneCreateOptions.milestone"
    @close="isMilestoneCreateModalVisible = false"
    @created="onMilestoneCreated"
    @refresh="$emit('refresh')"
  />
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/common/Icon';
import Detailes from './Detailes.vue';
import OverviewHeader from './OverviewHeader.vue';
import TaskModal from '@/components/inventions/tasks/TaskModal.vue';
import CreateMilestoneModal from './CreateMilestoneModal.vue';

const scrollTo = (difference, duration = 500, el) => {
  const startY = el.scrollTop;
  const startTime = performance.now();

  const step = () => {
    const progress = (performance.now() - startTime) / duration;
    const amount = easeOutCubic(progress);
    el.scrollTop = startY + amount * difference;
    if (progress < 0.99) {
      window.requestAnimationFrame(step);
    }
  };

  step();
};

// Easing function from https://gist.github.com/gre/1650294
const easeOutCubic = t => t;

const waiter = function () {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 300);
  });
};

export default {
  components: {
    TaskModal,
    'hub-icon': Icon,
    Detailes,
    OverviewHeader,
    CreateMilestoneModal
  },
  props: {
    collection: {
      type: Array,
      default: () => []
    },
    assignee: {
      type: String,
      default: null
    },
    assigneeTeams: {
      type: Array,
      default: () => []
    }
  },
  emits: ['more', 'refresh'],
  data() {
    return {
      selected: null,
      milestoneCreateOptions: null,
      isMilestoneCreateModalVisible: false,
      expanded: null,
      currentItemHeight: null,
      taskCreateOptions: null
    };
  },
  computed: {
    ...mapState({
      running: s => s.milestonesOverview.isRequestPending,
      failed: s => s.milestonesOverview.isRequestFailed,
      total: s => s.milestonesOverview.total,
      details: s => s.milestonesOverview.details
    })
  },
  watch: {
    collection(nw, ow) {
      if (!this.expanded) {
        return;
      }
      const oldExpandedIndex = ow.findIndex(item => `${item.id}_${item.nextMilestone.id}` === this.expanded);
      const newExpandedIndex = nw.findIndex(item => `${item.id}_${item.nextMilestone.id}` === this.expanded);
      if (newExpandedIndex === -1) {
        this.expanded = null;
      }
      if (newExpandedIndex > -1 && oldExpandedIndex > -1 && oldExpandedIndex === newExpandedIndex) {
        return;
      }

      const expandedContainer = document.getElementById(this.expanded);
      if (!expandedContainer) {
        return;
      }

      const sibling = expandedContainer.nextSibling || expandedContainer.previousSibling;
      const parent = expandedContainer.parentElement;

      const indexDiff = newExpandedIndex - oldExpandedIndex;
      if (indexDiff !== 0 && sibling) {
        const height = sibling.clientHeight;
        const difference = height * indexDiff;
        scrollTo(difference, 1000, parent);
      }
    }
  },
  methods: {
    onEditTask(task) {
      this.selected = task;
    },
    onCreateTask(invention, milestone = null) {
      this.taskCreateOptions = {
        inventionId: invention.id,
        task: {
          createNew: true,
          assignees: [],
          workflow: {
            ...milestone?.workflow,
            milestoneId: milestone?.id,
            milestoneTitle: milestone?.title || '',
            milestoneAssignees: milestone?.assignees || [],
            milestoneDueAt: milestone?.dueAt,
            milestoneClientDueDate: milestone?.clientDueDate,
            milestoneTemplateId: milestone?.workflow?.template?.id,
            stepId: null
          }
        },
        tasks: milestone ? milestone.tasks : []
      };
    },
    editMilestone(invention, milestone) {
      this.milestoneCreateOptions = { invention, milestone };
      this.isMilestoneCreateModalVisible = true;
    },
    taskModalClosed() {
      this.selected = null;
      this.taskCreateOptions = null;
    },

    async onCreateTaskModalClosed(event) {
      if (event && event.status == 'created') {
        this.selected = null;
        this.taskCreateOptions = null;
        this.$trackEvent(`Task created using 'Inventions' report`);
      }
    },
    async onMilestoneCreated() {
      this.$trackEvent(`Milestone created from 'My Active Inventions'`);
      this.isMilestoneCreateModalVisible = false;
      this.milestoneCreateOptions = null;
    },

    onCreateMilestone(invention) {
      this.milestoneCreateOptions = {
        invention,
        milestone: { createNew: true }
      };

      this.isMilestoneCreateModalVisible = true;
    },
    async taskEdited() {
      this.selected = null;
    },
    async toggleOverview(item) {
      this.currentItemHeight = null;
      await this.$store.dispatch('milestonesOverview/resetDetails');
      if (this.expanded === `${item.id}_${item.nextMilestone.id}`) {
        this.expanded = null;
      } else {
        this.expanded = null;
        await waiter();
        this.expanded = `${item.id}_${item.nextMilestone.id}`;
        await this.$store.dispatch('milestonesOverview/expand', this.expanded);
        const element = this.$refs.listRootRef?.querySelector('.detailes-wrapper');

        if (!element) {
          return;
        }

        const rects = element.getBoundingClientRect();
        this.currentItemHeight = rects.height;
        await this.$store.dispatch('milestonesOverview/getDetails', {
          id: item.id,
          assignee: this.assignee,
          filter: this.filter
        });
        this.$nextTick(() => {
          const child = element.children[0];
          const clone = child.cloneNode(true);
          clone.style.position = 'absolute';

          element.appendChild(clone);

          const { height } = clone.getBoundingClientRect();
          clone.remove();
          this.currentItemHeight = height;
          const self = this;
          setTimeout(() => {
            self.currentItemHeight = null;
          }, 200);
        });
      }

      // await this.$store.dispatch('milestonesOverview/expand', this.expanded);
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &:last-child {
    height: 100%;
    box-sizing: border-box;
  }

  &.overview-list {
    display: grid;
    align-content: flex-start;

    .detailes-wrapper {
      display: flex;
      justify-content: center;
      transition: height 0.2s linear;

      .details {
        width: 70%;
      }
    }
  }
  .details-loading {
    place-self: center;
    padding-top: 0.5rem;
    height: 2.3rem;
    overflow: hidden;
  }

  .detailes-loading {
    display: flex;
    justify-content: center;
    height: 5rem;
    align-items: center;
  }
}

.flip-list-move {
  transition: transform 0.2s linear;
  z-index: 100;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 0.7s linear;
}
.flip-list-enter-from,
.flip-list-leave-to {
  opacity: 0.4;
  transform: translateX(50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s linear;
  transition-delay: 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.detailes-fade {
  opacity: 1;
}
</style>
