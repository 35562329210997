<template>
  <div class="invention-notes">
    <div v-if="isRequestPending" class="loading-wrapper">
      <Icon name="loading" spin size="sm" />
    </div>
    <div v-else class="space">
      <Button v-if="isExpanded && !isAddingNote && notes?.length" variant="icon" class="icon" @click="isAddingNote = true">
        <Icon name="note-plus" size="md" />
      </Button>
      <Button variant="icon" class="icon" @click="toggleExpanded">
        <Icon
          :name="notes && notes.length ? (isExpanded ? 'note-outline' : 'note') : 'note-plus'"
          :color="notes && notes.length ? 'success' : ''"
          size="md"
        />
      </Button>

      <div v-if="isExpanded" class="invention-notes-wrapper" :class="{ center }">
        <sticky-note
          v-for="note in notes"
          :key="note.id"
          :editable="true"
          :data="note"
          color="blue"
          field="note"
          @edited="$e => onNotesEdited($e, note)"
          @deleted="onNotesDeleted(note)"
        />
        <sticky-note
          v-if="isAddingNote"
          :editable="true"
          :data="{}"
          :create-new="true"
          color="blue"
          field="note"
          @edited="onNoteAdded"
          @deleted="isAddingNote = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Icon from '@/components/common/Icon';
import StickyNote from '@/components/common/StickyNote';
import Button from '@/components/common/Button';

export default {
  components: {
    Icon,
    Button,
    StickyNote
  },

  props: {
    inventionId: {
      type: String,
      required: true
    },
    showByDefault: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: this.showByDefault,
      isAddingNote: false
    };
  },
  computed: {
    ...mapState({
      notes(s) {
        return s.inventionNotes.notes[this.inventionId] && s.inventionNotes.notes[this.inventionId].collection;
      },
      isRequestPending(s) {
        return s.inventionNotes.notes[this.inventionId] && s.inventionNotes.notes[this.inventionId].isRequestPending;
      }
    })
  },
  created() {
    this.$store.dispatch('inventionNotes/getNotesForInvention', this.inventionId);
  },
  methods: {
    toggleExpanded() {
      if (!this.notes.length) {
        this.isAddingNote = true;
        this.isExpanded = true;
        return;
      }
      this.isExpanded = !this.isExpanded;
    },
    async onNotesDeleted(note) {
      await this.$store.dispatch('inventionNotes/deleteNote', note);
    },
    async onNotesEdited(v, note) {
      await this.$store.dispatch('inventionNotes/updateNote', { id: note.id, payload: { note: v } });
    },
    async onNoteAdded(note) {
      try {
        await this.$store.dispatch('inventionNotes/createNote', { note, inventionId: this.inventionId });
      } finally {
        this.isAddingNote = false;
      }
    }
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.invention-notes {
  position: relative;
  display: inline-block;

  .icon {
    padding: 3px;
    &:hover {
      background: var(--theme-highlight);
    }
    border-radius: 0%;
  }
}

.space {
  display: flex;
  .invention-notes-wrapper {
    pointer-events: none;
    position: absolute;
    padding-right: 50px;
    padding-top: 5px;
    height: 200px;
    width: auto;
    z-index: 10;
    right: 0px;
    max-width: 60vw;
    min-width: 60vw;
    text-align: right;
  }

  .center {
    padding-right: 175px;
  }
}

.loading-wrapper {
  width: 100%;

  justify-content: center;
  text-align: center;
}
</style>
