<template>
  <div class="header">
    <InventionHeader :id="invention.id" :editable="false" />
  </div>
  <div v-if="isGetTemplateCollectionPending" class="milestones-loading">
    <hub-icon name="loading" spin size="lg" />
  </div>
  <transition-group v-else name="milestone-list" class="milestones-list detailes-fade" tag="ul">
    <li v-for="milestone of milestones" :key="milestone.id || +new Date()" class="list-item milestone-list-item">
      <div class="list" :class="{ expanded: expandedMilestoneIds.includes(milestone.id) }">
        <div class="list-item">
          <div></div>
          <div class="list-button" @click="toggleMilestone(milestone)">
            <hub-icon :name="expandedMilestoneIds.includes(milestone.id) ? 'chevron-up' : 'chevron-down'" size="md" />
          </div>
          <div v-if="milestone.finished || milestone.deleted" class="inactive">
            <hub-milestone-status :status="milestoneStatus(milestone)" size="md" />
          </div>
          <div v-else title="Create task" class="list-button" @click="onCreateTask(milestone)">
            <hub-icon name="plus" size="md" />
          </div>
          <div class="milestone-list-item-title">
            <div class="title" :class="{ inactive: milestone.finished || milestone.deleted }">{{ milestone.title }}</div>
            <div v-if="milestone.workflow?.id" class="buttons">
              <a
                v-if="$hasPermission('workflows') && milestone.workflow?.id && milestone.workflow?.template?.id"
                :href="`https://backoffice.${host}/workflows/${milestone.workflow.id}/milestones/${milestone.workflow.template.id}`"
                target="_blank"
                class="edit-milestone list-button"
                title="Show template in editor"
              >
                <hub-icon name="open-in-new" size="md" />
              </a>
              <div class="edit-milestone list-button" type="icon" title="Edit milestone" @click.stop="editMilestone(milestone)">
                <hub-icon name="pencil" size="md" />
              </div>
            </div>
          </div>
          <div class="milestone-list-item-dueAt">
            <div class="date">
              <AlertedDueAt
                v-if="!milestone.deleted && !milestone.finished"
                :due-at="milestone.clientDueDate"
                :emtpy-due-at-text="'no due date'"
                title="Client Due At"
              />
            </div>
            <div class="date">
              <AlertedDueAt
                v-if="!milestone.deleted && !milestone.finished"
                class="date"
                :due-at="milestone.dueAt"
                :emtpy-due-at-text="'no due date'"
                title="Milestone Due At"
              />
              <div v-else-if="milestone.finished" class="inactive" style="font-style: italic"><label>{{ `Finished ${milestone.finishedAt ? new Date(milestone.finishedAt).toLocaleDateString() : ''}` }}</label></div>
              <div v-else-if="milestone.deleted" class="inactive" style="font-style: italic"><label>{{ `Canceled ${milestone.deletedAt ? new Date(milestone.deletedAt).toLocaleDateString() : ''}` }}</label></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="expandedMilestoneIds.includes(milestone.id)" class="task-list">
        <div class="notes-section">
          <milestone-notes :milestone-id="milestone.id" />
        </div>
        <transition-group name="task-list" tag="ul" class="vertical-alignment">
          <li v-for="task of milestone.tasks" :key="task.id" class="task-list-item">
            <div class="next-pointer">
              <hub-icon v-if="task.id === nextTask.id" name="chevron-triple-right" size="md" title="My next task"></hub-icon>
            </div>
            <div class="task-list-status">
              <TaskStatusDropdown :task="task" @status-changed="$e => onStatusChange($e, task, milestone)" />
            </div>
            <div class="task-list-title">
              <div
                :class="{ bold: task.isCriticalDate && task.title, 'task-title': task.title, 'task-no-title': !task.title }"
                style="grid-row: 1"
                @click="onEditTask(task)"
              >
                {{ task.title || 'No title' }}
              </div>
            </div>
            <div class="task-list-notes">
              <div v-if="editingTaskId === task.id" class="field-wrapper">
                <hub-editor
                  ref="input"
                  v-model="editedNotes"
                  class="hub-editor"
                  :floating-panel="true"
                  :fixed-height="false"
                  @blur="onSaveNotes(task)"
                />
              </div>
              <styled-content v-else-if="task.notes?.trim()" class="notes-content" @click="onEditNotes(task)" v-html="task.notes" />
              <div v-else class="notes-placeholder" @click="onEditNotes(task)">Click here to add notes...</div>
            </div>
            <div class="task-list-assignees">
              <Assignees :highlight-options="highlightOptions?.id === task.id ? highlightOptions : null" :value="task.assignees || []" />
            </div>
            <div class="task-list-dueAt">
              <AlertedDueAt :due-at="task.dueAt" :class="{ bold: task.isCriticalDate }" :emtpy-due-at-text="'no due date'" />
              <div v-if="task.durationHours" class="duration">Duration: {{ task.durationHours }}h</div>
            </div>
          </li>
        </transition-group>
      </div>
    </li>
  </transition-group>
</template>

<script>
import Assignees from './Assignees.vue';
import AlertedDueAt from './AlertedDueAt.vue';
import Icon from '@/components/common/Icon.vue';
import MetadataModal from '@/components/inventions/tasks/MetadataForm';
import MilestoneNotes from '@/components/inventions/tasks/MilestoneNotes.vue';
import StyledContent from '@/components/common/editor/StyledContent';
import Editor from '@/components/common/editor/Editor';
import ToastService from '@/plugins/ToastService';
import TaskStatusDropdown from '@/components/common/TaskStatusDropdown';
import MilestoneStatus from '@/components/reports/components/MilestoneStatus';
import InventionHeader from '@/components/common/InventionHeader.vue';

import { createApp, h } from 'vue';

import { mapState } from 'vuex';

/* eslint-disable vue/one-component-per-file */
export default {
  components: {
    'hub-editor': Editor,
    'hub-milestone-status': MilestoneStatus,
    'hub-icon': Icon,
    AlertedDueAt,
    TaskStatusDropdown,
    Assignees,
    MilestoneNotes,
    StyledContent,
    InventionHeader
  },
  props: {
    invention: {
      type: Object,
      default: null
    },
    milestones: {
      type: Array,
      default: () => []
    },
    forUser: {
      type: String,
      default: ''
    },
    expandedMilestone: {
      type: String,
      default: null
    },
    forTeams: {
      type: Array,
      default: () => []
    }
  },
  emits: ['editTask', 'createTask', 'editMilestone', 'taskEdited'],
  data() {
    let expandedMilestoneIds;
    if (this.expandedMilestone || this.expandedMilestone === null) {
      expandedMilestoneIds = [this.expandedMilestone];
    } else {
      expandedMilestoneIds = this.milestones.filter(m => m.expanded).map(m => m.id);
    }
    return {
      editingTaskId: null,
      editedNotes: null,
      host: location?.host,
      highlight: {},
      expandedMilestoneIds
    };
  },
  computed: {
    ...mapState({
      milestoneTemplates: s => s.milestones.templates,
      templateCollection: s => s.tasks.templateCollection,
      isGetTemplateCollectionPending: s => s.tasks.isGetTemplateCollectionPending,
      me: s => s.identity.email,
      mode: s => s.settings.current.taskViewMode?.value
    }),
    nextTask() {
      return this.mode === 'My Next Task' ? this.invention?.myNextTask || this.invention?.nextTask : this.invention?.nextTask;
    },
    user() {
      return this.forUser || this.me;
    },
    highlightOptions() {
      var nextTask = this.nextTask;

      if (!nextTask) {
        return null;
      }

      const assignees = nextTask.assignees?.filter(a => a).map(a => a.toLowerCase().trim());
      if (!assignees?.length) {
        return null;
      }

      let result = {
        id: nextTask.id
      };
      if (assignees.includes(this.user.toLowerCase()) || this.forTeams.some(t => assignees.includes(t))) {
        if (nextTask.status === 'to do' || nextTask.status === 'in progress') {
          result.class = 'green';
          result.assignees = [this.user, ...this.forTeams];
          return result;
        }
        if (assignees.length === 1) {
          result.class = 'yellow';
          result.assignees = [this.user, ...this.forTeams];
          return result;
        }
      }
      if (assignees.includes('uspto')) {
        result.class = 'purple';
        result.assignees = ['uspto'];
        return result;
      }
      if (assignees.includes('client')) {
        result.class = 'orange';
        result.assignees = ['client'];
        return result;
      }
      if (assignees.includes('foreign counsel')) {
        result.class = 'red'; //'rgba(255,0,0,0.2)';
        result.assignees = ['foreign counsel'];
        return result;
      }

      const harrityAssignees = assignees.filter(a => a.includes('@harrityllp.com'));
      if (harrityAssignees) {
        result.class = 'yellow';
        result.assignees = harrityAssignees;
        return result;
      }
      return null;
    }
  },
  async created() {
    const uniqueIds = Array.from(new Set(this.milestones.map(m => m.workflow.id)));
    const milestoneIds = this.milestones.map(m => m.id);
    try {
      await Promise.all([
        this.$store.dispatch('milestones/bulkGetTemplates', uniqueIds),
        this.$store.dispatch('tasks/getTemplateCollection'),
        this.$store.dispatch('milestoneNotes/getNotesForMilestones', milestoneIds)
      ]);
    } finally {
    }

    this.highlight = this.highlightOptions;
  },
  methods: {
    toggleMilestone(milestone) {
      const index = this.expandedMilestoneIds.indexOf(milestone.id);
      if (index > -1) {
        this.expandedMilestoneIds.splice(index, 1);
      } else {
        this.expandedMilestoneIds.push(milestone.id);
      }
    },
    onEditTask(task) {
      this.$emit('editTask', task);
    },
    onEditNotes(task) {
      this.editingTaskId = task.id;
      this.editedNotes = task.notes;
    },
    async onSaveNotes(task) {
      this.editingTaskId = null;
      if (task.notes === this.editedNotes) {
        return;
      }

      await this.$store.dispatch('tasks/update', {
        ...task,
        assignees: task.assignees || [],
        notes: this.editedNotes
      });
      this.$emit('taskEdited', task);
    },
    onCreateTask(milestone) {
      this.$emit('createTask', milestone);
    },
    editMilestone(milestone) {
      this.$emit('editMilestone', milestone);
    },
    async onStatusChange(newStatus, task, milestone) {
      if(milestone.deleted || milestone.finished){
          const confirmResult = await this.$confirm({
            title: 'Revive milestone?',
            message: `Are you sure you want to revive milestone?`,
            confirm: 'Revive milestone'
          });
        if (!confirmResult) {
          return;
        }
      }

      let metadata = null;
      let form;
      let formData;
      const workflowTemplate = this.milestoneTemplates[task.workflow.id];
      if (workflowTemplate.isRequestPending || workflowTemplate.isRequestFailed) {
        this.$toast.error({
          title: 'Page didn`t load correctly.',
          message: `Please, try again later or contact our development team.`
        });
      }

      if (workflowTemplate && workflowTemplate.template) {
        const milestoneTemplate = workflowTemplate.template.find(template => template.id === task.workflow.milestoneTemplateId);
        if (milestoneTemplate) {
          const path = milestoneTemplate.paths.find(path => {
            return path.from.step === task.workflow.stepId && path.from.port === newStatus;
          });
          if (path && path.from.action && path.from.action.form) {
            form = path.from.action && path.from.action.form;
            const formValues = await this.$store.dispatch('steps/getForm', {
              template: form.properties,
              model: { thisTask: task },
              context: {
                inventionId: task.invention.inventionId || task.invention.id,
                milestoneId: task.workflow.milestoneId
              }
            });
            metadata = await this.requestMetadata({ title: form.name, properties: formValues }, task);
            if (!metadata) {
              this.isRequestPending = false;
              return;
            }

            formData = {
              name: form.name,
              properties: metadata
            };
          }
        }
      }
      const item = await this.$store.dispatch('tasks/getById', task.id);

      if (item) {
        try {
          item.assignees = item.assignees?.length ? item.assignees : [];
          await this.$store.dispatch('tasks/update', {
            ...item,
            status: newStatus,
            formData: formData || {}
          });
          this.$emit('taskEdited', task);
        } catch (e) {
          const message =
            e.response?.status === 403 ? `You don't have enough permissions to commit this action.` : JSON.parse(await e.response.text())?.message;
          this.$toast.error({
            title: 'Update failed',
            message: message || `Please, try again later or contact our development team.`
          });
        }
      }
    },
    milestoneStatus(milestone){
      if(milestone.finished){
        return 'finished';
      }
      if(milestone.deleted){
        return 'deleted';
      }
      return '';
    },
    async requestMetadata(form, task) {
      const self = this;
      return new Promise(resolve => {
        const closeWithResult = result => {
          instance.unmount();
          resolve(result);
        };
        const instance = createApp({
          render() {
            return h(MetadataModal, {
              form,
              task,
              onClose() {
                closeWithResult();
              },
              onSubmit(result) {
                closeWithResult(result);
              }
            });
          }
        });

        instance.use(self.$store);
        instance.use(ToastService);
        instance.mount(document.getElementById('modal'));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.milestones-loading {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  padding-top: 5px;
  min-height: 75px;
}

.milestones-list {
  overflow-y: visible !important;
  width: 100%;
  padding-bottom: 10px;

  .invention-title {
    display: grid;
    grid-template-rows: auto;
    font-weight: 500;
    color: var(--theme-on-surface-accent);
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

.list {
  .list-item {
    display: grid;
    grid-gap: 0;
    //border-bottom: 1px solid var(--theme-highlight);
    background: var(--theme-surface);
    font-size: 0.75rem;
    min-height: 1.5rem;
    align-items: center;

    .list-button {
      cursor: pointer;
      padding: 5px;
      height: 30px;
      &:hover {
        background: var(--theme-highlight);
      }
    }

    > * {
      //box-shadow: 0 0 1px 0 #eee inset;

      height: 100%;
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: pre-line;

      > label {
        overflow-x: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 100%;
      }
    }
    &.header {
      font-size: 0.75rem;
      color: var(--theme-on-background-accent);
      > * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 0.5rem;
      }
    }

    .list-item-actions {
      display: none;
    }
  }

  &:not(:last-child) {
    margin-right: 6px;
  }

  .task-list-status {
    align-items: flex-start;
  }

  .milestone-list-item {
    grid-template-rows: max-content max-content;
    background: transparent;
    //border-bottom: 1px solid var(--theme-highlight);

    border: 1px solid transparent;
    border-radius: 2px;

    &.expanded {
      border-color: var(--theme-highlight);
    }

    .edit-milestone {
      display: none;
    }

    &:hover {
      .edit-milestone {
        display: block;
      }
    }

    > .list:first-child {
      margin: 0;
      padding: 0;
      display: block;
      overflow: hidden;
      .list-item {
        grid-template-columns: 0px 30px 30px 1fr auto;
        background: transparent;
        border: 0 none;

        .milestone-list-item-title {
          justify-content: space-between;
          grid-column: 4;
          font-weight: 700;
          min-width: 200px;
          text-transform: uppercase;
          padding: 0;
          margin-left: 5px;

          .title {
            padding: 10px 10px 10px 0px;
            color: var(--theme-primary);
          }

          .buttons {
            display: flex;
          }
        }
        .milestone-list-item-dueAt {
          font-weight: 700;
          color: var(--theme-on-surface-accent);
          text-decoration: underline;
          grid-column: 6/6;
          min-width: 80px;
          gap: 15px;
          padding-right: 15px;

          display: grid;
          grid-template-columns: auto 70px;
          text-align: center;

          .date {
          }
        }
      }
    }
  }
  .inactive {
    opacity: 0.5;
  }
  .task-list {
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 0;
    grid-auto-rows: min-content;
    border: 1px solid var(--theme-highlight);
    border-radius: 2px;

    li:nth-child(odd) {
      background: var(--theme-surface);
    }

    .vertical-alignment {
      align-self: start;
    }

    .next-pointer {
      align-items: flex-start;
      justify-items: center;
      padding-right: 0;
      padding-left: 0.3rem;
      padding-top: 7px;
    }

    .task-list-enter-active {
      transition: transform 0.8s ease, background-color 10s linear, opacity 2s linear;
    }

    .task-list-enter-from {
      opacity: 0;
      transform: translateX(50px);
      background-color: var(--theme-surface);
    }

    .task-list-item {
      display: grid;
      grid-template-columns: 30px 30px 35% auto max-content max-content max-content;
      background: transparent;
      border-bottom: 0;
      padding: 3px 0;

      .notes-placeholder {
        display: none;

        font-size: 0.75rem;
        letter-spacing: 0.05rem;
        opacity: 0.4;
        padding: 10px 10px;
      }

      &:hover {
        .notes-placeholder {
          display: block;
        }
      }

      .task-list-title {
        min-width: 200px;
        padding-left: 5px;
        align-items: flex-start;
        align-content: flex-start;
      }

      .task-list-status {
        padding-top: 1px;
      }

      .task-title {
        cursor: pointer;
        padding: 10px 0;

        &:hover {
          text-decoration: underline;
        }
      }

      .task-no-title {
        cursor: pointer;
        padding: 10px 0;
        color: var(--theme-on-surface-accent);
        font-style: italic;

        &:hover {
          text-decoration: underline;
        }
      }

      .task-list-notes {
        font-style: italic;
        position: relative;
        min-width: 200px;
        .field-wrapper {
          width: 100%;

          .hub-editor {
            border: 1px solid var(--theme-highlight);
            padding: 0;
          }
        }

        .notes-content {
          border: 1px solid transparent;
          min-width: 200px;
          cursor: text;
        }
      }

      .task-list-assignees {
        padding-top: 7px;
        width: 120px;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .task-list-dueAt {
        display: block;
        margin-top: 0.25rem;
        align-items: flex-start;
        min-width: 80px;
        .duration {
          font-size: 0.6rem;
          font-style: italic;
        }
      }
    }
  }
}
.bold {
  font-weight: 700;
  font-size: 0.8rem;
}
.notes-section {
  align-self: start;
}
</style>
