<template>
  <div class="filters">
    <TextField
      :value="filter"
      class="filter"
      :clear-button="true"
      :debounce="true"
      :test-id="'milestone-overview-filter-input'"
      :placeholder="'Filter'"
      :initial-focus="true"
      @change="$e => $emit('onFilterChanged', $e)"
    />

    <label>for</label>
    <Assignees
      :value="assignee"
      :single="true"
      :shorthand="true"
      :mapper="prepareValue"
      placeholder=""
      :select-on-blur="true"
      @change="$e => $emit('onAssigneeChange', $e)"
    />
    <hub-icon v-if="assignee !== me" class="me-icon" name="account-arrow-left" title="Me" @click="$emit('onAssigneeChange')" />

    <label>by tag</label>
    <hub-multiselect
      :value="tagsOption"
      :taggable="true"
      :multiple="true"
      :create-option="false"
      placeholder="Milestone template tag"
      :options="tags"
      @change="$e => $emit('onTagsChange', $e)"
    />

    <label>sorted by</label>
    <hub-multiselect
      :value="sort"
      :can-deselect="false"
      :can-clear="false"
      :options="[
        { label: 'Client Date', value: 'cdd' },
        { label: 'Due Date', value: 'mda' }
      ]"
      @change="$e => $emit('onSortChange', $e)"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';

import Multiselect from '@/components/common/Multiselect.vue';
import TextField from '@/components/common/TextField';
import Assignees from '@/components/Assignees';
import Icon from '@/components/common/Icon';
export default {
  components: {
    'hub-multiselect': Multiselect,
    'hub-icon': Icon,
    Assignees,
    TextField
  },
  props: {
    filter: {
      type: String,
      default: ''
    },
    assignee: {
      type: String,
      default: ''
    },
    tagsOption: {
      type: Array,
      default: () => []
    },
    sort: {
      type: String,
      default: null
    }
  },
  emits: ['onFilterChanged', 'onTagsChange', 'onSortChange', 'onAssigneeChange'],
  data() {
    return {
      tags: []
    };
  },
  computed: {
    ...mapState({
      me: s => s.identity.email
    })
  },
  async created() {
    this.tags = await httpClient.get('/api/workflow/v2/workflows/tags');
  },
  methods: {
    prepareValue(value) {
      const label = value.includes('@') ? `${value.split('@')[0]} or ${value}` : `${value} team`;
      return { label, value };
    }
  }
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: center;
  gap: 10px;

  .filter {
    flex: 1;
  }

  .me-icon {
    cursor: pointer;

    &:hover {
      color: var(--theme-primary);
    }
  }

  .multiselect-wrapper {
    min-width: 300px;
  }
}
</style>
