<template>
  <hub-modal :visible="true" class="milestone-modal" closable @close="$emit('close')">
    <template #title
      ><div>{{ milestone.createNew ? 'Create' : 'Edit' }} milestone</div></template
    >
    <form v-if="!isFetchingMilestones" :class="{ dirty: v$.$anyDirty }" autocomplete="off" class="create-milestone-form" @submit.stop.prevent>
      <section class="create-milestone-form-body">
        <div v-if="!canEdit" class="form-row not-enough-rights-label">
          <span> You don't have enough permissions to edit this milestone</span>
        </div>
        <div class="form-row">
          <div class="milestone-templates-select">
            <p-multiselect
              v-model:value="templateId"
              label="Template"
              :options="milestones"
              :disabled="!milestone.createNew || !canEdit"
              :test-id="'milestone-template'"
              @change="onTemplateSelected"
            />
            <div v-if="!milestone.createNew && !milestones.find(m => m.value === templateId)" class="warning-icon">
              <hub-icon name="alert-circle-outline" size="md" color="warning" title="Tempalte is missing" />
            </div>
          </div>
          <ul v-if="v$.templateId.$invalid">
            <li v-if="v$.templateId.required.$invalid" class="error">Template should be selected</li>
          </ul>
        </div>
        <div class="form-row">
          <p-text-field v-model="title" label="Title" autocomplete="off" :test-id="'milestone-title'" :disabled="!canEdit" />
          <ul v-if="v$.title.$invalid">
            <li v-if="v$.title.required.$invalid" class="error">Title is required</li>
          </ul>
        </div>
        <div class="form-row">
          <div class="form-row-action-list">
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneDueAt('+1d')">+1d</p-button>
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneDueAt('+1w')">+1w</p-button>
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneDueAt('+1m')">+1m</p-button>
          </div>
          <hub-date
            v-model:value="dueAt"
            label="Due At"
            :disabled="!canEdit"
            :event-subject="`${title} ${subject}`"
            :event-body="`<p>${invention.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
          />
        </div>
        <div class="form-row">
          <div class="form-row-action-list">
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneClientDueDate('+1d')"
              >+1d</p-button
            >
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneClientDueDate('+1w')"
              >+1w</p-button
            >
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneClientDueDate('+1m')"
              >+1m</p-button
            >
          </div>
          <hub-date
            v-model:value="clientDueDate"
            label="Client Due Date"
            :disabled="!canEdit"
            :event-subject="`${title} ${subject}`"
            :event-body="`<p>${invention.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
          />
        </div>
        <div class="form-row">
          <p-button
            v-if="!(assignees.length === 1 && assignees[0] === email)"
            :disabled="!canEdit"
            class="claim-button"
            variant="text"
            color="primary"
            @click.stop.prevent="claim"
          >
            assign to me
          </p-button>
          <p-assignees v-model:value="assignees" label="Assigned to" placeholder="" :disabled="!canEdit" />
        </div>

        <div v-if="variables.length" class="variables">
          <div class="title">Additional info:</div>
          <div v-for="field of variables.filter(f => f.type === 'people')" :key="field.name" class="form-row">
            <p-assignees v-model:value="field.enteredValue" :label="field.name" placeholder="" :disabled="!canEdit" />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'date')" :key="field.name" class="form-row">
            <hub-date
              v-model:value="field.enteredValue"
              :label="field.name"
              placeholder=""
              :disabled="!canEdit"
              :event-subject="`${field.name} ${subject}`"
              :event-body="`<p>${invention.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
            />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'datetime')" :key="field.name" class="form-row">
            <hub-date
              v-model:value="field.enteredValue"
              :label="field.name"
              mode="datetime"
              placeholder=""
              :disabled="!canEdit"
              :event-subject="`${field.name} ${subject}`"
              :event-body="`<p>${invention.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
            />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'number' || f.type === 'string')" :key="field.name" class="form-row">
            <p-text-field
              v-model="field.enteredValue"
              :label="field.name"
              :test-id="field.name"
              autocomplete="off"
              :multiline="true"
              :disabled="!canEdit"
            />
          </div>
        </div>
      </section>
      <section v-if="canEdit && !terminalStatus" class="create-milestone-form-footer">
        <div>
            <p-button v-if="!milestone.createNew && !milestone.deleted" type="button" color="secondary" class="terminate" @click.prevent="cancelMilestone"> Cancel milestone </p-button>
            <p-button v-if="!milestone.createNew && !milestone.finished && !milestone.deleted" type="button" color="secondary" @click.prevent="finishMilestone"> Finish milestone </p-button>
        </div>
        <div>
          <p-button type="button" :disabled="isCreatingMilestone" @click.prevent="$emit('close')"> Close </p-button>
          <p-button
            type="button"
            color="primary"
            :disabled="(v$.$anyDirty && v$.$invalid) || isCreatingMilestone || isUpdatingMilestone"
            @click.prevent="submit"
          >
            {{ milestone.createNew ? 'Create' : 'Update' }}
          </p-button>
        </div>
      </section>
      <section v-else-if="canEdit" class="create-milestone-form-footer">
        <div></div>
        <div>
          <p-button type="button" :disabled="isCreatingMilestone" @click.prevent="$emit('close')"> Close </p-button>
          <p-button
            type="button"
            color="primary"
            :disabled="(v$.$anyDirty && v$.$invalid) || isCreatingMilestone || isUpdatingMilestone"
            @click.prevent="revive"
          >
          {{ milestone.finished ? 'Revive finished milestone' : 'Revive canceled milestone' }}
          </p-button>
        </div>
      </section>
    </form>
    <div v-else class="loader-wrapper">
      <hub-icon name="loading" spin></hub-icon>
    </div>
  </hub-modal>
</template>
<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';

import Modal from '@/components/common/Modal';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';

import TextField from '@/components/common/TextField';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import DateField from '@/components/common/DateField';
import Assignees from '@/components/Assignees';
import Icon from '@/components/common/Icon';
export default {
  components: {
    'hub-modal': Modal,
    'p-assignees': Assignees,
    'p-multiselect': Multiselect,
    'p-button': Button,
    'hub-date': DateField,
    'p-text-field': TextField,
    'hub-icon': Icon
  },
  props: {
    invention: {
      type: Object,
      required: true
    },
    milestone: {
      type: Object,
      default: () => ({})
    },
    creationEvent: {
      type: String,
      default: 'user.milestone.created'
    },
    model: {
      type: Object,
      default: () => ({})
    },
    references: {
      type: String,
      default: null
    }
  },
  emits: ['close', 'created'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      templateId: null,
      title: '',
      dueAt: null,
      clientDueDate: null,
      assignees: [],
      variables: [],
      canEdit: true,
      terminalStatus: this.milestone.finished || this.milestone.deleted
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email,
      milestones: s =>
        s.milestones.collection
          .filter(m => m.active)
          .map(({ id, title, ...rest }) => {
            return {
              ...rest,
              label: title,
              value: id
            };
          }),
      isCreatingMilestone: s => s.milestones.isCreateRequestPending,
      isFetchingMilestones: s => s.milestones.isGetCollectionRequestPending || s.milestones.getMilestoneInstanceRequestPending,
      milestoneInstance: s => s.milestones.milestoneInstance,
      isUpdatingMilestone: s => s.milestones.isUpdateRequestPending
    }),
    subject() {
      if (this.invention.references?.length) {
        return `(${this.invention.references.map(e => (e.includes(':') ? e.split(':')[1] : e)).join(' | ')})`.trim();
      }
      return '';
    },
    inventionUrl() {
      return `${window.location.origin}/inventions/${this.invention.id}`;
    }
  },
  async created() {
    const promises = [
      this.$store.dispatch('milestones/getCollection', { references: this.invention.references, workflowId: this.milestone.workflow?.id })
    ];

    if (this.milestone?.id) {
      promises.push(this.$store.dispatch('milestones/getMilestoneInstance', this.milestone.id));
    }

    await Promise.all(promises);

    if (this.milestone?.id && !this.milestone.createNew) {
      this.templateId = this.milestoneInstance.workflow?.template?.id;
      this.title = this.milestoneInstance?.title || '';
      this.dueAt = this.milestoneInstance.dueAt ? new Date(this.milestoneInstance.dueAt) : null;
      this.clientDueDate = this.milestoneInstance.clientDueDate ? new Date(this.milestoneInstance.clientDueDate) : null;
      this.assignees = this.milestoneInstance.createNew || this.milestoneInstance.assignees || [];

      const milestone = this.milestones.find(m => m.value === this.milestoneInstance.workflow.template.id);
      if (milestone) {
        this.variables =
          milestone.variables
            ?.filter(v => !v.source)
            .map(v => {
              let enteredValue;
              if (v.type === 'date' || v.type === 'datetime') {
                enteredValue = this.milestoneInstance.variables[v.property] ? new Date(this.milestoneInstance.variables[v.property]) : null;
              } else if (v.type === 'people') {
                enteredValue = [...this.milestoneInstance.variables[v.property]];
              } else {
                enteredValue = this.milestoneInstance.variables[v.property];
              }
              return { name: v.name, property: v.property, type: v.type, enteredValue };
            }) || [];

        this.canEdit = this.milestoneInstance.canEdit === undefined || this.milestoneInstance.canEdit;
      }
    }
    if (this.milestone.createNew) {
      this.claim();
    }
  },
  methods: {
    async onTemplateSelected(v) {
      const milestone = this.milestones.find(m => m.value === v);
      if (milestone) {
        this.title = milestone.label;
        this.dueAt = null;
        this.clientDueDate = null;
        const userCreateEvent = milestone.events.find(e => e.code === this.creationEvent);
        if (userCreateEvent?.action) {
          const form = await httpClient.post(`/api/workflow/execute/bind`, {
            template: userCreateEvent.action,
            model: {
              ...this.model
            },
            context: {
              inventionId: this.invention.id
            }
          });

          this.title = form?.title ?? milestone.label;
          this.dueAt = form?.dueAt;
          this.clientDueDate = form?.clientDueDate;
        }

        this.variables =
          milestone.variables?.filter(v => !v.source).map(v => ({ name: v.name, property: v.property, type: v.type, enteredValue: null })) || [];
      }
    },
    claim() {
      this.assignees = [this.email];
    },
    setMilestoneDueAt(mod) {
      const value = this.applyDateMod(mod, new Date());
      if (value) {
        this.dueAt = value;
      }
    },
    setMilestoneClientDueDate(mod) {
      const value = this.applyDateMod(mod, new Date());
      if (value) {
        this.clientDueDate = value;
      }
    },
    async revive() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      let variables = {};
      if (this.milestoneInstance) {
        variables = { ...this.milestoneInstance.variables };
      }
      const obj = {
        templateId: this.templateId,
        title: this.title,
        dueAt: this.dueAt ? this.dueAt : null,
        clientDueDate: this.clientDueDate ? this.clientDueDate : null,
        assignees: this.assignees,
        inventionId: this.invention.id,
        ...(this.milestone.finished && {finished: false}),
        ...(this.milestone.deleted && {deleted: false}),
        variables: {
          ...this.variables.reduce((acc, curr) => {
            acc[curr.property] = curr.enteredValue;
            return acc;
          }, variables)
        }
      };

      await this.$store.dispatch('milestones/update', { id: this.milestone.id, ...obj });
      this.$emit('close');
    },
    applyDateMod(mod, value = new Date()) {
      switch (mod) {
        case '+1d':
          const tomorrow = new Date(value);
          tomorrow.setDate(value.getDate() + 1);
          return tomorrow;
        case '+1w':
          const inWeek = new Date(value);
          inWeek.setDate(value.getDate() + 7);
          return inWeek;
        case '+1m':
          const inMonth = new Date(value);
          inMonth.setMonth(value.getMonth() + 1);
          return inMonth;
        default:
          return null;
      }
    },
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      let variables = {};
      if (this.milestoneInstance) {
        variables = { ...this.milestoneInstance.variables };
      }
      const obj = {
        templateId: this.templateId,
        title: this.title,
        dueAt: this.dueAt ? this.dueAt : null,
        clientDueDate: this.clientDueDate ? this.clientDueDate : null,
        assignees: this.assignees,
        inventionId: this.invention.id,
        variables: {
          ...this.variables.reduce((acc, curr) => {
            acc[curr.property] = curr.enteredValue;
            return acc;
          }, variables)
        }
      };
      if (this.milestone.createNew) {
        if (this.references) {
          obj.references = this.references;
        }
        try {
          await this.$store.dispatch('milestones/create', obj);
        } catch (e) {
          if (e.response.status === 400) {
            const response = await e.response.json();

            this.$toast.error({
              title: 'Failed to create milestone',
              message: response.message
            });
          }
        }
      } else {
        await this.$store.dispatch('milestones/update', { id: this.milestone.id, ...obj });
      }

      this.$emit('created');
    },
    async cancelMilestone() {
      const confirmResult = await this.$confirm({
        title: 'Cancel milestone?',
        message: `Are you sure you want to cancel milestone? All tasks will be canceled.`,
        confirm: 'Cancel milestone'
      });
      if (confirmResult) {
        await this.$store.dispatch('milestones/delete', this.milestone.id);
        this.$emit('close');
        this.$emit('refresh');
      }
    },
    async finishMilestone() {
      const confirmResult = await this.$confirm({
        title: 'Finish milestone?',
        message: `Are you sure you want to finish milestone? All tasks will be canceled.`,
        confirm: 'Finish milestone'
      });
      if (confirmResult) {
        await this.$store.dispatch('milestones/finishMilestoneInstance', this.milestone.id);
        this.$emit('close');
        this.$emit('refresh');
      }
    }
  },

  validations() {
    return {
      title: { required },
      templateId: { required }
    };
  }
};
</script>
<style lang="scss">
.milestone-modal {
  .modal {
    min-height: 90%;
    width: 1024px;
    grid-template-rows: max-content minmax(0, 1fr) max-content;
  }
  .modal > div {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .modal > footer {
    padding: 0.25rem 0 0.5rem 0;
  }
  form {
    background: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.milestone-modal {
  z-index: 500;
  .milestone-templates-select {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;
    align-items: flex-end;

    .warning-icon {
      padding-bottom: 5px;
    }
  }

  .loader-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .create-milestone-form {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 1fr auto;
    padding: 0.5rem 0 0.5rem;
    background: var(--theme-surface);
    min-height: 100%;
    height: 100%;

    .create-milestone-form-body {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(12, max-content);
      min-height: 0;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .form-row {
      position: relative;
      margin-bottom: 0.5rem;
      padding-right: 1px;
      &.center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .claim-button {
        position: absolute;
        right: 0;
        font-size: 0.6rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      .form-row-action-list {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;

        > button {
          font-size: 0.6rem;
          padding-right: 0;
          padding-left: 0;
          margin-left: 0.5rem;
        }
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
      margin: 0;
    }
    &.dirty {
      .error {
        display: block;
      }
    }

    .variables {
      border: 1px solid var(--theme-on-background);
      padding: 15px;
      .title {
        margin-bottom: 20px;
      }
    }
    .create-milestone-form-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      vertical-align: bottom;
      padding: 0.5rem var(--theme-scroll-width) 0.25rem 0;
    }

    .terminate {
      margin-right: 0.5rem;
    }
  }

  .not-enough-rights-label {
    font-style: italic;
    color: var(--theme-warning);
    font-weight: 600;
  }
}
</style>
