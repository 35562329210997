<template>
  <header>
    <div v-if="isLoading" class="updating-progress">
      <div class="icon"><hub-icon name="loading" spin size="sm" class="icon"></hub-icon></div>
      Loading...
    </div>
    <div v-else-if="isUpdating" class="updating-progress">
      <div class="icon"><hub-icon name="loading" spin size="sm"></hub-icon></div>
      Updating...
    </div>
    <div v-else>
      <div class="references">
        <hub-reference-list
          v-if="thisInvention.references"
          :invention="thisInvention"
          :show-navigation="false"
          :show-prefixes="true"
          :external-navigation-enabled="true"
          :edit-mode="editable && editMode"
          @references:updated="onReferenceUpdated"
        />
        <div class="references-buttons">
          <InventionNotes :invention-id="invention.id" :center="!editable" />
          <template v-if="!previewMode && editable">
            <div v-if="!editMode">
              <hub-button variant="icon" class="refresh" title="Edit invention" @click="edit"
                ><hub-icon name="pencil" size="md"></hub-icon
              ></hub-button>
              <hub-button v-if="matterNumber?.length || fipId?.length" variant="icon" class="refresh" title="Synchronize invention" @click="sync"
                ><hub-icon name="autorenew" size="md" :spin="hover" @mouseover="hover = true" @mouseleave="hover = false"></hub-icon
              ></hub-button>
            </div>
            <div v-else>
              <hub-button variant="icon" class="refresh" title="Submit" @click="submit"
                ><hub-icon name="check" size="md" :disabled="isUpdating"></hub-icon
              ></hub-button>
              <hub-button variant="icon" class="refresh" title="Revert" @click="revert"
                ><hub-icon name="close" size="md" :disabled="isUpdating"></hub-icon
              ></hub-button>
            </div>
          </template>
        </div>
      </div>
      <div v-if="!editMode" class="title">
        <label>
          {{ thisInvention?.title }}
        </label>
      </div>
      <hub-text-field v-else v-model="thisInvention.title" class="invention-title-edit" :spellcheck="true" />
      <div v-if="!editMode" class="people">
        <span v-if="inHouseAttorneys">
          In House Attorneys:
          <label>{{ inHouseAttorneys }}</label>
        </span>
        <span v-if="assignees">
          Assignees:
          <label>{{ assignees }}</label>
        </span>
        <span v-if="inventors">
          Inventors:
          <label>{{ inventors }}</label>
        </span>
        <span v-if="responsibleAttorneys">
          Responsible Attorneys:
          <label>{{ responsibleAttorneys }}</label>
        </span>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

import Button from '@/components/common/Button';
import ReferenceList from '@/components/common/ReferenceList';
import Icon from '@/components/common/Icon';

import TextField from '@/components/common/TextField';
import InventionNotes from '../inventions/tasks/InventionNotes';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-reference-list': ReferenceList,
    'hub-text-field': TextField,
    InventionNotes
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updated', 'sync'],
  data() {
    return {
      inventionSync: {
        inProgress: false,
        applicationScheduled: false,
        fipSynced: false
      },
      hover: false,
      editMode: false,
      beforeEdit: {
        title: '',
        reference: []
      },
      thisInvention: {
        id: this.id,
        title: '',
        references: []
      }
    };
  },
  computed: {
    ...mapState({
      invention: s => s.inventions.item,
      isUpdating: s => s.inventions.isUpdating,
      isLoading: s => s.inventions.isGetRequestPending
    }),
    previewMode() {
      return this.$route.query.previewMode === 'true';
    },
    matterNumber() {
      return (this.thisInvention && this.thisInvention.references?.find(r => r.startsWith('HARRITY:'))?.split(':')[1]) || '';
    },
    fipId() {
      return (this.thisInvention && this.thisInvention.references?.find(r => r.startsWith('FIP:'))?.split(':')[1]) || '';
    },
    applicationNumber() {
      return ((this.thisInvention && this.thisInvention.references?.find(r => r.startsWith('US:'))?.split(':')[1]) || '')
        .replace('/', '')
        .replace(',', '');
    },
    inHouseAttorneys() {
      const docketingInvention = this.thisInvention?.docketingInvention;

      if (!docketingInvention) {
        return null;
      }

      return `${docketingInvention.inHouseAttorneysPersons?.map(p => `${p.firstName} ${p.lastName}`.trim())?.join(' | ')}`.trim();
    },
    responsibleAttorneys() {
      const docketingInvention = this.thisInvention?.docketingInvention;

      if (!docketingInvention) {
        return null;
      }

      return `${docketingInvention.responsibleAttorneysPersons?.map(p => `${p.firstName} ${p.lastName}`.trim())?.join(' | ')}`.trim();
    },
    inventors() {
      const docketingInvention = this.thisInvention?.docketingInvention;

      if (!docketingInvention) {
        return null;
      }

      return `${docketingInvention.inventorsPersons?.map(p => `${p.firstName} ${p.lastName}`.trim())?.join(' | ')}`.trim();
    },
    assignees() {
      const docketingInvention = this.thisInvention?.docketingInvention;

      if (!docketingInvention) {
        return null;
      }
      return `${docketingInvention.assigneesPersons?.map(p => `${p.firstName} ${p.lastName}`.trim())?.join('; ')}`.trim();
    }
  },
  watch: {
    id: async function(prev, next) {
      this.loadInvention();
    },
    invention() {
      this.thisInvention = this.invention;
    }
  },
  async created() {
    this.loadInvention();
  },

  methods: {
    async sync() {
      this.hover = false;
      if (!this.matterNumber && !this.fipId) {
        return;
      }

      try {
        this.inventionSync = {
          inProgress: true,
          applicationScheduled: false,
          fipSynced: false,
          applicationNumber: this.applicationNumber
        };

        this.$emit('sync', this.inventionSync);

        if (this.applicationNumber) {
          await httpClient.post(`/api/uspto/downloads/`, { collection: [this.applicationNumber] });
          this.inventionSync.applicationScheduled = true;
        }

        if (this.fipId) {
          await httpClient.get(`/api/fip-connector/matters/byFipId/FIPID:${this.fipId}`, null, {
            'x-no-cache': true
          });
        } else {
          await httpClient.get(`/api/fip-connector/matters/${this.matterNumber}`, null, {
            'x-no-cache': true
          });
        }

        this.inventionSync.fipSynced = true;

        await new Promise(resolve => setTimeout(resolve, 1000));

        await this.loadInvention();
      } catch (error) {
        logger.error(error);
      } finally {
        this.inventionSync = {
          inProgress: false,
          applicationScheduled: false,
          fipSynced: false
        };
        this.$emit('sync', this.inventionSync);
      }
    },
    edit() {
      this.beforeEdit = {
        title: this.thisInvention.title,
        references: this.thisInvention.references
      };
      this.editMode = true;
    },
    async submit() {
      this.editMode = false;
      await this.$store.dispatch('inventions/updateInvention', {
        id: this.thisInvention.id,
        title: this.thisInvention.title,
        references: this.thisInvention.references
      });

      this.$emit('updated');
    },
    revert() {
      this.editMode = false;
      this.thisInvention = {
        title: this.beforeEdit.title,
        references: this.beforeEdit.references
      };
    },
    onReferenceUpdated(event) {
      this.thisInvention.references = event;
    },
    async loadInvention() {
      if (!this.id) {
        return;
      }

      await this.$store.dispatch('inventions/getByIdWithDocketing', this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  .references {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    min-height: 26px;

    .references-buttons {
      display: flex;
    }

    .refresh {
      padding: 3px;
      &:hover {
        background: var(--theme-highlight);
      }
      border-radius: 0%;
    }
  }

  .people {
    padding-top: 3px;

    span {
      font-size: 0.75rem;
      font-weight: 500;
      padding-right: 10px;
      color: var(--theme-on-surface-accent);
      opacity: 0.7;

      label {
        font-style: oblique;
        text-transform: uppercase;
      }
    }
  }

  .title {
    padding-top: 3px;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      text-transform: uppercase;
      color: var(--theme-on-surface-accent);
    }
  }

  .updating-progress {
    font-style: italic;
    font-size: 0.7rem;
    opacity: 0.8;
    padding-left: 0.5rem;
    display: flex;
  }

  .icon {
    padding-right: 5px;
  }
}
</style>
