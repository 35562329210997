<template>
  <div class="searching">
    <div class="status">
      <hub-icon name="loading" spin size="mdx"></hub-icon>
      <label>Searching</label>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
export default {
  components: {
    'hub-icon': Icon
  }
};
</script>

<style lang="scss">
.searching {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    margin-left: 10px;
  }
}
</style>
